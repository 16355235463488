function Footer() {
  return (
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-lg-auto col-md-8 col-10">
            <h1 className="text-white">&nbsp;</h1>
          </div>

          <div className="col-lg-4 col-md-6 col-12">
            <h4 className="my-4">Contact Info</h4>

            <p>
              <a href="mailto:admin@clearhammer.com">
                <i className="fa fa-envelope mr-2 footer-icon"></i>
                admin@clearhammer.com
              </a>
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-lg-auto col-md-8 col-12">
            <p>Copyright &copy; 2022 Clear Hammer</p>
          </div>

          <div className="col-lg-4 mx-lg-auto col-md-6 col-12">
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
