import logo from "../../images/vcogs2.png";

function Hero() {
  return (
    <section className="hero hero-bg d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-10 col-12 d-flex flex-column justify-content-center align-items-center">
            <div className="hero-text">
              <h1 className="text-white" data-aos="fade-up">
                Take your e-commerce business to the next level with{" "}
                <strong>Visual COGS</strong> by Clear Hammer
              </h1>

              <a
                href="https://visualcogs.com"
                className="custom-btn btn-bg btn mt-3"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Find Out More!
              </a>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div
              className="hero-image mt-5"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <img src={logo} className="img-fluid" alt="working girl" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Hero;
