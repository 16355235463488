import Footer from "./Footer";
import Hero from "./Hero";
import "./Home.css";
import Nav from "./Nav";

function Home() {
  return (
    <>
      <Nav />
      <Hero />
      {/* <About /> */}
      <Footer />
    </>
  );
}

export default Home;
